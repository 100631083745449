var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "v-dialog",
        {
          staticStyle: { overflow: "hidden" },
          attrs: {
            justify: "center",
            value: _vm.value,
            persistent: "",
            width: "1300",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "fill",
              staticStyle: { overflow: "hidden" },
              attrs: { fluid: "", height: "750" },
            },
            [
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-8 mt-8",
                      attrs: { icon: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:value", false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("video-player", {
                    staticClass: "vjs-custom-skin",
                    attrs: { options: _vm.playerOptions },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }