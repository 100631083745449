var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$auth.isLoading || !_vm.$auth.isAuthenticated
    ? _c(
        "v-overlay",
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      )
    : _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-app-bar",
            { attrs: { app: "", color: "main", dark: "" } },
            [
              _c(
                "v-app-bar-nav-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$auth.isAuthenticated,
                      expression: "$auth.isAuthenticated",
                    },
                  ],
                  staticClass: "mx-auto",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.drawer = !_vm.drawer
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.drawer ? "mdi-backburger" : "mdi-menu")),
                  ]),
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: { path: "/" } } },
                [
                  _vm.tenantsWithLogos.includes("" + _vm.$auth.tenant)
                    ? _c("v-img", {
                        staticClass: "ml-6",
                        attrs: {
                          "max-height": "110",
                          "max-width": "110",
                          src: require("@/assets/" +
                            _vm.$auth.tenant +
                            "_logo.png"),
                          alt: _vm.$auth.tenant + " Logo",
                        },
                      })
                    : _c(
                        "v-app-bar-nav-icon",
                        [
                          _c("v-icon", { attrs: { color: "#fff" } }, [
                            _vm._v("mdi-home"),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  staticClass: "pt-10",
                  attrs: {
                    "open-on-hover": "",
                    transition: "slide-y-transition",
                    bottom: "",
                    right: "",
                    "offset-y": "",
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { staticClass: "mr-2", attrs: { icon: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-help-rhombus-outline"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: { width: "200", tile: "" },
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c("v-subheader", [_vm._v("Tutorial Videos")]),
                          _c(
                            "v-list-item-group",
                            { attrs: { color: "primary" } },
                            _vm._l(_vm.videoLinks, function (link, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: i,
                                  on: {
                                    click: function ($event) {
                                      return _vm.openVideoPlayer(link.id)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(link.label) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "auto" } },
                [_c("PortalNavMenu", { attrs: { active: "billing" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", color: "toolbar" },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c("v-divider"),
                      _c(
                        "div",
                        {
                          class: [!_vm.miniVariant ? "pa-2" : "pa-1"],
                          staticStyle: { height: "64px" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              style: _vm.styleObject,
                              attrs: { block: "", id: "logoutBtn" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.logout()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "#fff" } }, [
                                _vm._v("mdi-logout"),
                              ]),
                              !_vm.miniVariant
                                ? _c("span", [_vm._v("Logout")])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list",
                { attrs: { nav: "" } },
                _vm._l(_vm.navLinks, function (link, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      attrs: {
                        to: { path: "" + link.path },
                        exact: "",
                        link: "",
                      },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(link.icon))])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(link.text))])],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-main",
            [
              _c("AlertBar"),
              _vm.hasPortalAccess
                ? _c("router-view")
                : _c(
                    "v-alert",
                    { attrs: { type: "error", text: "", border: "left" } },
                    [
                      _vm._v(
                        " You don't have authorized permissions to access this site. "
                      ),
                    ]
                  ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    "multi-line": "",
                    bottom: "",
                    rounded: "lg",
                    height: "60",
                    transition: "slide-y-reverse-transition",
                    timeout: _vm.snackbar.timeout,
                    color: _vm.snackbar.color,
                  },
                  model: {
                    value: _vm.snackbar.showing,
                    callback: function ($$v) {
                      _vm.$set(_vm.snackbar, "showing", $$v)
                    },
                    expression: "snackbar.showing",
                  },
                },
                [
                  _c("v-row", { attrs: { dense: "", align: "center" } }, [
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(_vm._s(_vm.snackbar.icon)),
                        ]),
                        _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "end" } },
                    [
                      _vm.snackbar.status === "error"
                        ? _c(
                            "v-btn",
                            {
                              attrs: { small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbar.showing = false
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            {
              staticClass: "justify-space-between",
              attrs: { color: "main", dark: "", app: "" },
            },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v(" iPro "),
                _c("span", { staticClass: "registeredTrademark" }, [
                  _vm._v("®"),
                ]),
                _vm._v(
                  " Systems, LLC. © " + _vm._s(new Date().getFullYear()) + " "
                ),
              ]),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("v. " + _vm._s(_vm.version)),
              ]),
            ]
          ),
          _vm.modal
            ? _c("VideoPlayer", {
                attrs: { id: _vm.id, value: _vm.modal },
                on: {
                  "update:value": function ($event) {
                    _vm.modal = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }