var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "archive pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { staticClass: "pb-0 mb-0 pt-0", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pb-2 mb-0 px-0 mt-0 pt-2" },
            [
              _c("v-data-table", {
                attrs: {
                  dense: "",
                  "items-per-page": _vm.pageSize,
                  headers: _vm.headers,
                  height: _vm.tableSize,
                  items: _vm.items,
                  loading: _vm.loading,
                  "item-key": "id",
                  "fixed-header": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-toolbar-title",
                              { staticClass: "ml-2 title" },
                              [_vm._v(" Billing ")]
                            ),
                            _c("v-divider", {
                              staticClass: "mx-4",
                              attrs: { inset: "", vertical: "" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }